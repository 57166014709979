body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Custom buttons styles begin */
/* .sendEmailButton {
  padding: 10px 20px;
} */

.editButton {
  color: rgba(245, 127, 23, 1);
}

.addButton {
  text-align: right;
  padding-right: 40px;
  margin-bottom: 20px;
}

.rotate:hover {
  animation: rotation 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(180deg);
  }
}
/* Custom buttons styles end */

/* ReactTable styles begin */
.ReactTable .rt-thead.-filters input {
  border: none;
  background: none;
}

/* [role='columnheader'] */
.ReactTable .rt-th {
  /* white-space: unset; */
  white-space: pre-line;
  font-weight: bold;
  display: block;
  outline: none;
}

.ReactTable .rt-td {
  white-space: pre-line;
  margin: auto;
}

.ReactTable > div.rt-table > div.rt-thead > div {
  align-items: center;
}

.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgba(5, 124, 72, 0.8) !important;
}

.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgba(245, 0, 87, 0.8) !important;
}
/* ReactTable styles end */

/* Custom selects styles start */
.dropdown200 {
  width: 200px;
}
.dropdown150 {
  width: 130px;
}
.dropdown100 {
  width: 100px;
}
/* Custom selects styles end */

.logo {
  background-image: url('/img/logo.png');
  width: 100%;
  height: 70px;
  margin-bottom: 15px;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 20px;
}

.btn-main-style {
  background: #56c568;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  padding: 8px 15px;
  cursor: pointer;
}
input.error {
  border: 1px solid red;
}
.input-feedback {
  position: static;
  margin-top: 8px;
  color: red;
  font-size: 14px;
  min-width: 170px;
  display: block;
  z-index: 1;
}
.no-label-select label + .MuiInput-formControl {
  margin-top: 0;
}
.fileInput {
  position: absolute;
  left: -9999px;
}
.notificationPanel {
  z-index: 999;
  display: none;
  right: -400px;
  left: auto;
  position: fixed;
  top: 64px;
  height: calc(100vh - 64px);
  width: 100%;
  max-width: 400px;
  background: white;
  box-shadow: -5px 0 5px -5px #333;
  transition: right 0.3s ease;
  overflow-y: scroll;
}
.notificationItem {
  padding: 10px;
}
.notificationItem:first-child {
  margin-top: 15px;
}
.notificationItem.unreaded {
  background: rgba(0, 0, 0, 0.1);
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}
.notificationPanelActive {
  right: 0px;
  display: block;
}

.MuiGrid-item {
  overflow: hidden;
  text-overflow: ellipsis;
}
.client-link {
  color: black;
}
.dashboard-heading {
  margin-bottom: 15px;
}
.notificationItem {
  color: black;
}
.notificationItem a {
  color: black;
  font-weight: 600;
  text-decoration: none;
}

.notificationItem a svg {
  font-size: 18px;
}
